import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { VectorMap } from "react-jvectormap";

import { Card, Dropdown } from "react-bootstrap";

import { MoreHorizontal } from "react-feather";
import usePalette from "../../hooks/usePalette";
import { useDispatch, useSelector } from "react-redux";
import countryList from "react-select-country-list";
import { getCountryDistribution } from "../../redux/slices/dashboard";
import NotyfContext from "../../contexts/NotyfContext";
import Loader from "../spinners/Loader";

function WorldMap({ period, reload }) {
  const palette = usePalette();
  const [rawData, setRawData] = useState([]);
  const [distribution, setDistribution] = useState([]);
  const [type, setType] = useState("data_sessions");
  const countries = countryList().getData();
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const by_country = useSelector((state) => state.dashboard.by_country);
  const notyf = useContext(NotyfContext);
  const [activeUser, setActiveUser] = useState({});
  const scaleColors = [
    palette["gray-200"],
    whitelabel?.backgroundColor ?? "#00acdc",
  ];
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const options = {
    map: "world_mill",
    hoverOpacity: 0.7,
    hoverColor: false,
    zoomOnScroll: false,
    regionStyle: {
      initial: {
        fill: palette["gray-200"],
      },
    },
    containerStyle: {
      width: "100%",
      height: "100%",
    },
    series: {
      regions: [
        {
          values: distribution, // object with country codes as keys
          scale: scaleColors, // colors used for choropleth
          normalizeFunction: "polynomial", // function used to normalize distribution data
        },
      ],
    },

    onRegionTipShow: (e, el, code) => {
      const countryName = el.html() || "";
      const countryValue = distribution[code] || 0;
      if (type === "data_volume") {
        el.html(
          `${countryName} : ${parseFloat(
            countryValue / 1000.0 / 1000.0
          ).toFixed(2)}MB`
        );
      } else {
        el.html(`${countryName} : ${countryValue}`);
      }
    },

    onRegionOut: (e, code) => {
      // Hide the built-in tooltip by manipulating the tooltip element directly
      document.querySelector(".jvectormap-tip").style.display = "none";
    },

    // onRegionTipHide: (e, code) => {
    //   el.html(""); // Clear the tooltip content
    // },
    backgroundColor: "transparent",
  };

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 250);
  }, [palette]);

  // useEffect(() => {
  //   return () => {
  //     document.querySelectorAll(".jvectormap-tip").forEach((element) => {
  //       element.remove();
  //     });
  //   };
  // });

  useEffect(() => {
    const fetchCountryDistribution = async () => {
      setIsLoading(true);
      await dispatch(getCountryDistribution({ period, type }))
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            dismissible: false,
            ripple: true,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, [500]);
        });
    };

    if (activeUser.id) {
      fetchCountryDistribution();
    }
  }, [activeUser, period, type, reload]);

  useEffect(() => {
    setDistribution(convertToCountryCodeDistribution(by_country));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [by_country]);

  const convertToCountryCodeDistribution = (result) => {
    let countryCodeDistribution = {};
    result.forEach((data) => {
      const countryData = countries.find(
        (country) => country.label === data.country
      );
      if (countryData) {
        let count = data.count;
        if (type === "dataVolume") {
          count = convertToMegabytes(count);
        }
        countryCodeDistribution[countryData.value] = count;
      }
    });
    return countryCodeDistribution;
  };

  const convertToMegabytes = (bytes) => {
    return parseFloat(bytes / 1000.0 / 1000.0).toFixed(2);
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <div className="card-actions float-end">
          <Dropdown align="end">
            <Dropdown.Toggle as="a" bsPrefix="-">
              <MoreHorizontal />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setType("data_volume")}>
                {t("dashboard.dataVolume")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setType("alerts")}>
                {t("dashboard.alerts")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setType("data_sessions")}>
                {t("dashboard.dataSessions")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setType("zero_sessions")}>
                {t("dashboard.zeroSessions")}
              </Dropdown.Item>
              {/* <Dropdown.Item onClick={() => setType("extra_charge")}>
                {t("Extra Charge")}
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Card.Title className="mb-0">
          {type === "data_volume"
            ? t("dashboard.dataVolume")
            : type === "data_sessions"
            ? t("dashboard.dataSessions")
            : type === "alerts"
            ? t("dashboard.alerts")
            : type === "zero_sessions"
            ? t("dashboard.zeroSessions")
            : // : type === "extra_charge"
              // ? t("Extra Charge")
              null}
        </Card.Title>
      </Card.Header>
      <Card.Body className="p-2">
        {isLoading && <Loader />}
        {!isLoading && (
          <div className="map-container" style={{ height: 279 }}>
            {by_country && Object.keys(by_country).length > 0 ? (
              <VectorMap {...options} key={JSON.stringify(by_country)} />
            ) : (
              <div className="d-flex justify-content-center align-items-center h-100 w-100">
                {t("No data available")}
              </div>
            )}
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

export default WorldMap;
